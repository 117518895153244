import logoRecoloca from "../../assets/img/logo_recoloca.svg"
import { Button, Col, Container, Navbar, Spinner, Stack } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import { useState } from "react"

import { useAuth } from "../../utils/hooks/useAuth"

export function Login() {
  const [loading, setLoading] = useState(false)
  const { signIn } = useAuth()

  return (
    <Container fluid className="background-login">
      <Col className="col-1 w-100">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="#home">
              <img src={logoRecoloca} alt="" style={{ width: "180px" }} />
            </Navbar.Brand>
            <Navbar.Toggle className="bg-success" />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text className="d-flex gap-4 text-light sm-flex-column">
                <NavLink to="/" className="text-light text-md">
                  Site
                </NavLink>
                <NavLink to="/" className="text-light text-md">
                  Instagram
                </NavLink>
                <NavLink to="/" className="text-light text-md">
                  Youtube
                </NavLink>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Col>
      <Col className="col-3 w-100 text-light">
        <Stack className="col-md-5 mx-auto text-center flex-column">
          <h1>Bora fazer um mundo melhor?</h1>
          <p className="lead">
            A Recoloca é a plataforma pioneira especializada na recolocação
            estratégica de produtos!
          </p>
          <Col className="mx-auto ">
            {loading ? (
              <Spinner animation="border" variant="success" />
            ) : (
              <Button
                variant="success"
                className="btn btn-lg "
                onClick={() => {
                  signIn()
                  setLoading(true)
                }}
              >
                Acessar
              </Button>
            )}
          </Col>
        </Stack>
      </Col>
      <Col className="col-1 w-100 p-4">
        <Stack className="col-md-5 mx-auto text-light text-center">
          <span className="text-white-50">
            Developed by <strong>GoTech</strong>.
          </span>
        </Stack>
      </Col>
    </Container>
  )
}
