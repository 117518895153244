export type CountryConfiguration = {
  repurposal: {
    changePriceExternalFormalization: boolean
    acceptNegotiatedPrice: boolean
    settingsRecolocaBitExternalFormalizationEnabled: boolean
    settingsMinPriceExternalFormalizationEnabled: boolean
    showUnitValueOptions: boolean
    showImageFlowFeedbackExternalFormalization: boolean
    showPaymentPolicy: boolean
    showEmailInput: boolean
  }
  invoices: {
    showAutomaticCreate: boolean
    showInvoiceIssuerStateRegistration: boolean
    showInvoiceReceiverStateRegistration: boolean
  }
  bills: {
    paymentTypesWithFixedData: Array<number>
  }
  customers: {
    enableCRMCustomerImport: boolean
  }
  reports: {
    showRepurposalMap: boolean
  }
  phoneMask: {
    maskCel: string
    maskPhone: string
    placeholder: string
    formattedNumber: {
      phone: {
        length: number
        regex: RegExp
        format: string
        maskFormat: {
          regex: RegExp
          format: string
        }
      }
    }
    phoneCountryCode: string
    validationPhone: {
      length: number
    }
  }
  timezone: {
    name: string
  }
}

export const countryConfigurations: { [key: string]: CountryConfiguration } = {
  BR: {
    repurposal: {
      changePriceExternalFormalization: true,
      acceptNegotiatedPrice: false,
      settingsRecolocaBitExternalFormalizationEnabled: true,
      settingsMinPriceExternalFormalizationEnabled: true,
      showUnitValueOptions: false,
      showImageFlowFeedbackExternalFormalization: true,
      showPaymentPolicy: true,
      showEmailInput: true,
    },
    invoices: {
      showAutomaticCreate: true,
      showInvoiceIssuerStateRegistration: true,
      showInvoiceReceiverStateRegistration: true,
    },
    bills: {
      paymentTypesWithFixedData: [1, 3],
    },
    customers: {
      enableCRMCustomerImport: true,
    },
    reports: {
      showRepurposalMap: true,
    },
    phoneMask: {
      maskCel: "(99) 99999-9999",
      maskPhone: "(99) 9999-9999",
      placeholder: "(99) 99999-9999",
      formattedNumber: {
        phone: {
          length: 11,
          regex: /(\d{2})(\d{4,5})(\d{4})/,
          format: "($1) $2-$3",
          maskFormat: {
            regex: /(\d{2})\s?(\d{2})\s?(\d{4,5})\s?(\d{4})/,
            format: "+$1 ($2) $3-$4",
          },
        },
      },
      phoneCountryCode: "55",
      validationPhone: {
        length: 12,
      },
    },
    timezone: {
      name: "America/Bahia",
    },
  },
  CO: {
    repurposal: {
      changePriceExternalFormalization: false,
      acceptNegotiatedPrice: true,
      settingsRecolocaBitExternalFormalizationEnabled: false,
      settingsMinPriceExternalFormalizationEnabled: false,
      showUnitValueOptions: true,
      showImageFlowFeedbackExternalFormalization: false,
      showPaymentPolicy: false,
      showEmailInput: false,
    },
    invoices: {
      showAutomaticCreate: false,
      showInvoiceIssuerStateRegistration: false,
      showInvoiceReceiverStateRegistration: false,
    },
    bills: {
      paymentTypesWithFixedData: [],
    },
    customers: {
      enableCRMCustomerImport: false,
    },
    reports: {
      showRepurposalMap: false,
    },
    phoneMask: {
      maskCel: "(99) 99999-9999",
      maskPhone: "(999) 9999-9999",
      placeholder: "(99) 99999-9999",
      formattedNumber: {
        phone: {
          length: 10,
          regex: /(\d{10})/,
          format: "$1",
          maskFormat: {
            regex: /(\d{2})(\d{3})(\d{7})/,
            format: "+$1 $2 $3",
          },
        },
      },
      phoneCountryCode: "57",
      validationPhone: {
        length: 10,
      },
    },
    timezone: {
      name: "America/Bogota",
    },
  },
  MX: {
    repurposal: {
      changePriceExternalFormalization: false,
      acceptNegotiatedPrice: true,
      settingsRecolocaBitExternalFormalizationEnabled: false,
      settingsMinPriceExternalFormalizationEnabled: false,
      showUnitValueOptions: false,
      showImageFlowFeedbackExternalFormalization: false,
      showPaymentPolicy: false,
      showEmailInput: false,
    },
    invoices: {
      showAutomaticCreate: false,
      showInvoiceIssuerStateRegistration: false,
      showInvoiceReceiverStateRegistration: false,
    },
    bills: {
      paymentTypesWithFixedData: [],
    },
    customers: {
      enableCRMCustomerImport: false,
    },
    reports: {
      showRepurposalMap: false,
    },
    phoneMask: {
      maskCel: "(99) 9999-9999",
      maskPhone: "(99) 9999-9999",
      placeholder: "(99) 9999-9999",
      formattedNumber: {
        phone: {
          length: 10,
          regex: /(\d{2})(\d{4})(\d{4})/,
          format: "($1) $2-$3",
          maskFormat: {
            regex: /(\d{2})\s?(\d{2})\s?(\d{4})\s?(\d{4})/,
            format: "+$1 ($2) $3-$4",
          },
        },
      },
      phoneCountryCode: "52",
      validationPhone: {
        length: 10,
      },
    },
    timezone: {
      name: "America/Mexico_City",
    },
  },
}
