import { api } from "../api"

export async function validateUserLoggedIn() {
  try {
    const response = await api.get("/auth/validate_token")
    return response.data
  } catch (error) {
    return error
  }
}

export async function logout() {
  try {
    const { data } = await api.delete("/auth/sign_out", {
      headers: { "Content-Type": "application/json" },
    })
    return data
  } catch (error) {
    console.error(error)
  }
}
