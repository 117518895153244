import { FilterMultiSelect } from "../FilterMultiSelect"
import { useInfiniteQuery } from "@tanstack/react-query"
import { regionsList } from "../../../../../../services/addresses.service"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../../../utils/hooks/useCountryConfigs"

function getNextPageParam(lastPage: SimpleIndexRegionsQuery) {
  return lastPage?.page + 1
}

function defaultRenderValue(region: baseData) {
  return region.id
}

export function FilterRegionsSelect(props: FilterRegionsSelectProps) {
  const { label, renderValue = defaultRenderValue, ...otherConfigs } = props
  const [search, setSearch] = useDebounceValue("", 500)
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  async function getCustomers({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexRegionsQuery> {
    const { data } = await regionsList({ pageNumber: pageParam, search })
    return { regions: data.regions, count: data.count, page: pageParam }
  }

  const { fetchNextPage, isLoading, data } = useInfiniteQuery({
    queryKey: ["regions", search, countryCode],
    queryFn: getCustomers,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 5 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  function handleScroll() {
    fetchNextPage()
  }

  return (
    <>
      {FilterMultiSelect<baseData>({
        renderValue,
        disabled: props.disabled,
        options: data?.pages.flatMap((page) => page.regions),
        isLoading: isLoading,
        onChange: handleSearch,
        onScroll: handleScroll,
        count: data?.pages.slice(-1)[0].count,
        labelName: label,
        ...otherConfigs,
      })}
    </>
  )
}
