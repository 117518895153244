import { Nav } from "react-bootstrap"
import { CustomLink } from "../../Customs/CustomLink"

export function HeaderButton(props: HeaderButtonProps) {
  const { title, icon, to, onClick } = props

  return (
    <li>
      <CustomLink
        as={Nav.Link}
        onClick={onClick}
        data-testid={props["data-testid"]}
        className="text-light text-decoration-none d-flex flex-column gap-1 cursor-pointer"
        title={title}
        href={to}
      >
        {icon}
        {title}
      </CustomLink>
    </li>
  )
}
