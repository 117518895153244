import { countries } from "countries-list"
import { useLocation, useNavigate } from "react-router"
import { Locale } from "date-fns/locale"
import {
  createContext,
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  findPluralRule,
  gerLanguages,
  getCountryCode,
  getCountryConfig,
  getCurrency,
  getCurrencySymbol,
  getDateFnsLocale,
  getIntLocale,
  getPluralRules,
  storeCountryCode,
} from "./utils"
import { CountryConfiguration } from "../../../configs/countryConfig"

export type CountryCode = keyof typeof countries

type UseCountryConfigsProps = {
  locale: Intl.Locale
  currency: {
    code: string
    symbol: string
  }
  languages: Array<string>
  dateLocale: Locale
  findPluralRule: (number?: number) => string
  countryCode: [CountryCode, Dispatch<SetStateAction<CountryCode>>]
  configs: CountryConfiguration
}

const CountryConfigsContext = createContext<UseCountryConfigsProps | undefined>(
  undefined,
)

export const CountryConfigsProvider = ({
  children,
}: React.PropsWithChildren) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [countryCode, setCountryCode] = useState<CountryCode>(
    (getCountryCode() as CountryCode) || "BR",
  )

  function findPlural(number?: number) {
    return findPluralRule(getPluralRules(countryCode), number)
  }

  const value: UseCountryConfigsProps = useMemo(() => {
    storeCountryCode(countryCode)
    return {
      locale: getIntLocale(countryCode),
      currency: {
        code: getCurrency(countryCode),
        symbol: getCurrencySymbol(countryCode),
      },
      dateLocale: getDateFnsLocale(countryCode),
      findPluralRule: findPlural,
      countryCode: [countryCode, setCountryCode],
      configs: getCountryConfig(countryCode),
      languages: gerLanguages(countryCode),
    }
  }, [countryCode])

  useEffect(() => {
    if (countryCode !== getCountryCode()) {
      const { search: _search, ...otherInfo } = location
      navigate(otherInfo)
    }
  })

  return (
    <CountryConfigsContext.Provider value={value}>
      <Fragment key={countryCode}>{children}</Fragment>
    </CountryConfigsContext.Provider>
  )
}

export function useCountryConfigs(): UseCountryConfigsProps {
  const context = useContext(CountryConfigsContext)
  if (context === undefined) {
    throw new Error(
      "useCountryConfigs must be used within a CountryConfigsProvider",
    )
  }
  return context
}
