import { createContext, ReactNode } from "react"

export type ToastPosition =
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left"
  | "bottom-center"
  | "top-center"
export type ToastSize = "sm" | "lg" | "xl" | "xxl"
export type ToastVariant =
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "secondary"

export interface ShowToastOptions {
  duration?: number
  action?: ReactNode
  position?: ToastPosition
  size?: ToastSize
  isButtonClosed?: boolean
}

interface ToastContextProps {
  success: (message: string, options?: ShowToastOptions) => void
  danger: (message: string, options?: ShowToastOptions) => void
  warning: (message: string, options?: ShowToastOptions) => void
  info: (message: string, options?: ShowToastOptions) => void
  secondary: (message: string, options?: ShowToastOptions) => void
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined)

export default ToastContext
