import { createContext, ReactElement, useState } from "react"

interface CatalogProviderTypes {
  children: React.ReactNode
}

interface CatalogContextTypes {
  handleFeedbackSuccess: (message: ReactElement, show: boolean) => void
  handleFeedbackError: (message: string, show: boolean) => void
  feedbackSuccess: {
    show: boolean
    message: string | JSX.Element
  }
  feedbackError: {
    show: boolean
    message: string
  }
  handleIdCatalog: (id: string) => void
  idCatalog?: string | null
  dataItem?: CatalogListItem
}

export const CatalogContext = createContext({} as CatalogContextTypes)

export default function CatalogProvider({ children }: CatalogProviderTypes) {
  const [idCatalog, setIdCatalog] = useState<string>("")

  const [feedbackSuccess, setFeedbackSuccess] = useState<{show: boolean, message: string | JSX.Element}>({
    show: false,
    message: "",
  })

  const [feedbackError, setFeedbackError] = useState({
    show: false,
    message: "",
  })

  function handleFeedbackSuccess(message: string | JSX.Element, show: boolean) {
    setFeedbackSuccess({
      show,
      message: message,
    })
  }

  function handleFeedbackError(message: string, show: boolean) {
    setFeedbackError({
      show,
      message: message,
    })
  }

  function handleIdCatalog(id: string) {
    setIdCatalog(id)
  }

  return (
    <CatalogContext.Provider
      value={{
        handleFeedbackError,
        handleFeedbackSuccess,
        feedbackSuccess,
        feedbackError,
        idCatalog,
        handleIdCatalog,
      }}
    >
      {children}
    </CatalogContext.Provider>
  )
}
