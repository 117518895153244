import React, { createContext, useContext, useState } from "react"

interface StatusFeedback {
  show: boolean
  message: string
}

interface FeedbackContextProps {
  successFeedback: StatusFeedback
  errorFeedback: StatusFeedback
  setSuccessFeedback: (msg: StatusFeedback) => void
  setErrorFeedback: (msg: StatusFeedback) => void
  clearFeedbacks: () => void
}

const FeedbackContext = createContext<FeedbackContextProps | undefined>(
  undefined,
)

export const useFeedbackContext = () => {
  const context = useContext(FeedbackContext)
  if (!context) {
    throw new Error("useFeedbackContext must be used within a FeedbackProvider")
  }
  return context
}

export const FeedbackProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [successFeedback, setSuccessFeedback] = useState<StatusFeedback>({
    show: false,
    message: "",
  })
  const [errorFeedback, setErrorFeedback] = useState<StatusFeedback>({
    show: false,
    message: "",
  })

  const clearFeedbacks = () => {
    setSuccessFeedback({ show: false, message: "" })
    setErrorFeedback({ show: false, message: "" })
  }

  return (
    <FeedbackContext.Provider
      value={{
        successFeedback,
        errorFeedback,
        setSuccessFeedback,
        setErrorFeedback,
        clearFeedbacks,
      }}
    >
      {children}
    </FeedbackContext.Provider>
  )
}
