import { lazy } from "react"

export const StockCheckinExternal = lazy(
  () => import("../../pages/StockCheckinExternal"),
)

export const Performance = lazy(
  () => import("../../pages/Reports/RepurposalsPerformance"),
)

export const Home = lazy(() => import("../../pages/Home"))

export const CatalogList = lazy(() => import("../../pages/CatalogList"))

export const CatalogItem = lazy(() => import("../../pages/CatalogItem"))

export const CatalogManufacturers = lazy(
  () => import("../../pages/CatalogManufacturers"),
)

export const Stock = lazy(() => import("../../pages/Stock"))
export const StockView = lazy(() => import("../../pages/Stock/StockView"))

export const StockCheckins = lazy(() => import("../../pages/StockCheckins"))

export const StockNew = lazy(() => import("../../pages/StockNew"))

export const StockHistory = lazy(() => import("../../pages/StockHistory"))

export const Customers = lazy(() => import("../../pages/Customers"))

export const CustomersGroups = lazy(() => import("../../pages/CustomersGroups"))

export const CustomersView = lazy(() => import("../../pages/CustomersView"))

export const Financial = lazy(() => import("../../pages/Financial"))

export const ExtenalBill = lazy(() => import("../../pages/External/Bill"))

export const NewBillets = lazy(
  () => import("../../pages/Financial/Receipts/NewBillets"),
)

export const FeedBackNewBillets = lazy(
  () => import("../../pages/Financial/Receipts/NewBillets/FeedBackNewBillets"),
)

export const NewRepurposal = lazy(
  () => import("../../pages/RepurposalRegister"),
)

export const ExternalRepurposal = lazy(
  () => import("../../pages/External/Repurposal"),
)
export const ViewRepurposal = lazy(() => import("../../pages/RepurposalView"))

export const ViewRepurposalPDF = lazy(
  () => import("../../pages/RepurposalView/components/RepurposalPDF"),
)

export const Repurposal = lazy(() => import("../../pages/RepursoalList"))

export const StockPdf = lazy(() => import("../../pages/Stock/StockPdf"))

export const ExternalPortalConfigs = lazy(
  () => import("../../pages/Configs/ExternalPortalConfigs"),
)

export const ExternalPortalReports = lazy(
  () => import("../../pages/Configs/ExternalPortalReports"),
)

export const ExternalUsers = lazy(
  () => import("../../pages/Configs/ExternalUsers"),
)

export const KpisReport = lazy(() => import("../../pages/Reports/Kpis"))
export const DestinyRecolocations = lazy(
  () => import("../../pages/Reports/Maps/DestinyRecolocations"),
)

export const CommercialPerformance = lazy(
  () => import("../../pages/Reports/Kpis/Commercial"),
)

export const RepurposalsPerformance = lazy(
  () => import("../../pages/Reports/RepurposalsPerformance"),
)
export const SmartRecRecolocationsOptimizer = lazy(
  () => import("../../pages/SmartRecRecolocationsOptimizer"),
)

export const SmartRecStockDriver = lazy(
  () => import("../../pages/SmartRecStockDriver"),
)

export const Logistics = lazy(() => import("../../pages/Logistics"))

export const CheckinsTracking = lazy(
  () => import("../../pages/Reports/CheckinsTracking"),
)
