function convertString(value: string[]) {
  return value.length > 1 ? value : value[0]
}

function convertMultiSelectString(value: string[]) {
  return value
}

function convertNumber(value: string[]) {
  return value.map(Number)
}

function convertDate(value: string[]) {
  return JSON.parse(value[0]) as DateFilter
}

export const filterConvertions: Record<
  string,
  (items: string[]) => NewFilterTypes
> = {
  string: convertString,
  multiSelectString: convertMultiSelectString,
  number: convertNumber,
  date: convertDate,
}
