import { createRoot } from "react-dom/client"
import { App } from "./App"
import "bootstrap/dist/css/bootstrap.min.css"
import "./scss/global.scss"
import Provider from "./providers"
import ReactGA from "react-ga4"
import { initializeSentry } from "./initializers/sentry"
import { setupDateFns } from "./initializers/dateFns"

const root = createRoot(document.getElementById("root")!)
const production = import.meta.env.VITE_AMBIENTE === "production"

window.addEventListener("vite:preloadError", () => {
  window.location.reload()
})

production && ReactGA.initialize(import.meta.env.VITE_GA_TRACKING)

initializeSentry()
setupDateFns()

root.render(
  <Provider>
    <App />
  </Provider>,
)
