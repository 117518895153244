import { Container, Nav } from "react-bootstrap"

import logoRecoloca from "../../assets/img/logo_recoloca.svg"

import {
  BsBagCheck,
  BsBoxArrowLeft,
  BsBoxSeam,
  BsBuildings,
  BsFileText,
  BsGraphUpArrow,
  BsGrid,
  BsTruck,
} from "react-icons/bs"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { capitalizeText } from "../../utils/Format"
import { useAuth } from "../../utils/hooks/useAuth"
import { CountryDropdown } from "./CountryDropdown"
import { HeaderButton } from "./HeaderButton"

export function Header() {
  const { t } = useTranslation()
  const { signOut } = useAuth()
  const navigate = useNavigate()

  const environment: string = import.meta.env.VITE_AMBIENTE

  return (
    <div
      className={`text-white ${
        environment === "homolog"
          ? "bg-success"
          : environment === "dev"
            ? "bg-secondary"
            : "bg-dark"
      } px-3 py-2`}
    >
      <Container>
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
          <div className=" d-flex">
            <Nav.Link
              className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none"
              onClick={() => navigate("/")}
            >
              <img
                src={logoRecoloca}
                alt=""
                style={{ width: "180px", height: "29.88px" }}
              />
            </Nav.Link>

            <CountryDropdown />
          </div>
          <Nav className="col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            <HeaderButton
              to={"/customers"}
              icon={<BsBuildings size={24} />}
              title={t("Customers.customers")}
              data-testid="customersHeaderButton"
            />
            <HeaderButton
              to={"/catalogs"}
              icon={<BsGrid size={24} />}
              title={t("Catalog.catalog")}
              data-testid="catalogHeaderButton"
            />
            <HeaderButton
              data-testid="Estoque"
              to={"/estoque"}
              icon={<BsBoxSeam size={24} />}
              title={t("Stock.stock")}
            />
            <HeaderButton
              to={"/recolocacoes"}
              icon={<BsBagCheck size={24} />}
              title={capitalizeText(t("Repurposal.repurposal.other"))}
              data-testid="repurposalHeaderButton"
            />
            <HeaderButton
              to={"/financial/invoices"}
              icon={<BsFileText size={24} />}
              title={t("Billing.financial")}
              data-testid="billing-header-button"
            />
            <HeaderButton
              to={"/logistics/deliveries"}
              icon={<BsTruck size={24} />}
              title={capitalizeText(t("Deliveries.delivery.other"))}
              data-testid="deliveries-header-button"
            />
            <HeaderButton
              to="/reports/kpis/billing-results"
              icon={<BsGraphUpArrow size={24} />}
              title={t("Reports.reports")}
            />
            <HeaderButton
              to="#"
              onClick={signOut}
              icon={<BsBoxArrowLeft size={24} />}
              title={t("Global.actions.leave")}
            />
          </Nav>
        </div>
      </Container>
    </div>
  )
}
