import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns"
import { t } from "i18next"
import { useState } from "react"
import { DateRange } from "react-day-picker"
import { useCountryConfigs } from "../../../../utils/hooks/useCountryConfigs"

export default function DatePickerFilterPresets({
  handleSelectedDate,
}: {
  handleSelectedDate: (date: Date | undefined | DateRange) => void
}) {
  const today = new Date()
  const { languages } = useCountryConfigs()

  const presetsOptions: { [key: string]: DateRange } = {
    today: {
      from: startOfDay(today),
      to: today,
    },
    thisWeek: {
      from: startOfWeek(today),
      to: endOfWeek(today),
    },
    last30Days: {
      from: subDays(today, 30),
      to: today,
    },
    thisMonth: {
      from: startOfMonth(today),
      to: endOfMonth(today),
    },
    lastMonth: {
      from: startOfMonth(subMonths(today, 1)),
      to: endOfMonth(subMonths(today, 1)),
    },
    thisYear: {
      from: startOfYear(today),
      to: endOfYear(today),
    },
    lastYear: {
      from: startOfYear(subYears(today, 1)),
      to: endOfYear(subYears(today, 1)),
    },
  }

  const [presetSelected, setPresetSelected] = useState<string>()

  return (
    <div className="list-group pe-3 border-end rounded-0">
      {Object.keys(presetsOptions).map((presetId) => {
        const preset = presetsOptions[presetId]

        return (
          <a
            key={`datePickerPreset_${presetId}`}
            className={`list-group-item list-group-item-action border-0 ${presetId == presetSelected ? "active" : ""}`}
            onClick={() => {
              setPresetSelected(presetId)
              handleSelectedDate(preset)
            }}
          >
            {t(`Global.dates.${presetId}`, { lng: languages[0] })}
          </a>
        )
      })}
    </div>
  )
}
