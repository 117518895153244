import { useEffect, useState } from "react"
import { Button, Col, Collapse, Container, Row } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { filterConvertions } from "../../../utils/filterConvertions"
import { FiltersFactor } from "./components/FiltersFactor"
import { useTranslation } from "react-i18next"
import { NewFilterButton } from "./components/NewFIlterButton"
import { useFilters } from "../../../utils/hooks/useFilters"
import { filterIsNotEmpty } from "./utils/filterIsNotEmpty"
import { isArray } from "lodash"

function NewFilter({
  onClick,
  filterParams,
  beforeApllyParams,
}: NewFilterProps) {
  const { t } = useTranslation()
  const {
    show,
    setShow,
    filter,
    setFilter,
    setHasAppliedFilters,
    optionsConfig,
    setOptionsConfig,
  } = useFilters()
  const [reset, setReset] = useState(false)
  const [urlParams, setUrlParams] = useSearchParams()

  useEffect(() => {
    if (!filterParams) return
    const initialState: NewFilterValues = {}

    Object.entries(filterParams).forEach(
      ([key, filters]: [string, Array<string>]) => {
        const convert = filterConvertions[key]
        filters.forEach((name) => {
          const value = urlParams.getAll(name)
          if (value.length > 0) {
            initialState[name] = convert(value)
          }
        })
      },
    )
    const newParams = beforeApllyParams
      ? beforeApllyParams(initialState)
      : initialState
    applyParams(newParams)
    setFilter(newParams)

    setHasAppliedFilters(filterIsNotEmpty(newParams))

    return () => {
      setHasAppliedFilters(false)
      setOptionsConfig([])
      setFilter({})
      setShow(false)
    }
  }, [])

  function handleRemoveFilter() {
    setFilter({})
    setReset(true)
  }

  function applyParams(filter: NewFilterValues) {
    if (!filterParams) return
    const newParams = new URLSearchParams(window.location.search)

    filterParams.date?.forEach((key) => {
      newParams.delete(key)
      const value = filter[key]
      value !== undefined && newParams.set(key, JSON.stringify(value))
    })
    const otherFIlters = [
      ...(filterParams.number ? filterParams.number : []),
      ...(filterParams.string ? filterParams.string : []),
      ...(filterParams.multiSelectString ? filterParams.multiSelectString : []),
    ]
    otherFIlters.forEach((key) => {
      newParams.delete(key)
      const value = filter[key]
      if (value) {
        if (typeof value === "string" || typeof value === "number") {
          newParams.set(key, String(value))
        } else if (isArray(value)) {
          value.forEach((v: number | string) => {
            newParams.append(key, String(v))
          })
        }
      }
    })
    setUrlParams(newParams)
  }

  function handleClick() {
    applyParams(filter)
    onClick(filter)

    setHasAppliedFilters(filterIsNotEmpty(filter))
  }

  return (
    <Collapse in={show} data-testid="filterContent">
      <div className="border-bottom bg-body-secondary w-100">
        <Container>
          <Row className="mb-4">
            {optionsConfig.map((config, index) => (
              <div className="dropdown-input-filter" key={index}>
                <FiltersFactor
                  filter={filter}
                  setFilter={setFilter}
                  config={config}
                  reset={reset}
                  setReset={setReset}
                />
              </div>
            ))}
          </Row>
        </Container>
        <div className="border-bottom" />
        <Container>
          <Col className="d-flex justify-content-end mt-2 mb-2">
            <Button
              className=" bg-transparent border-0 text-primary me-2"
              data-testid="removeFilters"
              onClick={handleRemoveFilter}
            >
              {t("Global.actions.removeFilters")}
            </Button>
            <Button data-testid="applyFilters" onClick={handleClick}>
              {t("Global.actions.apply")}
            </Button>
          </Col>
        </Container>
      </div>
    </Collapse>
  )
}

export default Object.assign(NewFilter, {
  Button: NewFilterButton,
  Content: NewFilter,
})
