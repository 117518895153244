import styled, { css } from "styled-components"
import { Button } from "react-bootstrap"

interface ButtonPagination {
  pageNumber?: string
}

export const ButtonPagination = styled(Button)<ButtonPagination>`
  ${({ pageNumber }) =>
    String(pageNumber) === "..." &&
    css`
      border: 0;
    `}

  ${({ pageNumber }) =>
    Number(pageNumber) < 10 &&
    css`
      padding: 0 8px;
    `}
    margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 10px;
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 12px 2px;

  nav {
    display: inline-flex;
    gap: 6px;
    border-radius: 8px;
  }
`
