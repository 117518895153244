import { ErrorTemplate } from "../../../components/ErrorTemplate"

export function Forbidden() {
  return (
    <ErrorTemplate
      errorType="Forbidden"
      errorTitle="Acesso Negado"
      errorDescription="Você não tem permissão para esta requisição."
      errorSolution="Se você acredita que isso é um erro, por favor, entre em contato com
      o administrador do sistema."
    />
  )
}
