import { createContext, useContext, useState } from "react"
import { useFilterProps } from "./useFilters"

const FiltersContext = createContext<useFilterProps | undefined>(undefined)

export function FiltersProvider({ children }: React.PropsWithChildren) {
  const [show, setShow] = useState(false)
  const [optionsConfig, setOptionsConfig] = useState<Array<OptionConfig>>([])
  const [filter, setFilter] = useState<NewFilterValues>({})
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false)

  return (
    <FiltersContext.Provider
      value={{
        show,
        setShow,
        optionsConfig,
        setOptionsConfig,
        filter,
        setFilter,
        hasAppliedFilters,
        setHasAppliedFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  )
}

export function useFilters() {
  const context = useContext(FiltersContext)
  if (context === undefined) {
    throw new Error("useFilters must be used within a FiltersProvider")
  }
  return context
}
