import React from "react"
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
  FaQuestionCircle,
} from "react-icons/fa"
import { ToastSize, ToastVariant } from "../../context/ToastContext"

interface ToastProps {
  variant: ToastVariant
  message: string
  action: React.ReactNode
  size: ToastSize
  onMouseEnter: () => void
  onMouseLeave: () => void
  onClose: () => void
  isButtonClosed?: boolean
}

export default function Toast({
  variant,
  message,
  action,
  size,
  onMouseEnter,
  onMouseLeave,
  onClose,
  isButtonClosed = true,
}: ToastProps) {
  const variantStyles = {
    success: { backgroundColor: "#E9F4F0", color: "#0F5132" },
    danger: { backgroundColor: "#FBE9EB", color: "#842029" },
    warning: { backgroundColor: "#FFF9E6", color: "#997404" },
    info: { backgroundColor: "#E7F1FF", color: "#084298" },
    secondary: { backgroundColor: "#E9ECEF", color: "#495057" },
  }

  const variantIcons = {
    success: <FaCheckCircle size={16} />,
    danger: <FaExclamationCircle size={16} />,
    warning: <FaExclamationTriangle size={16} />,
    info: <FaInfoCircle size={16} />,
    secondary: <FaQuestionCircle size={16} />,
  }

  const sizeClass = {
    sm: "toast-sm",
    lg: "toast-lg",
    xl: "toast-xl",
    xxl: "toast-xxl",
  }

  return (
    <div
      className={`toast show d-flex justify-content-between rounded-2 p-2 ${sizeClass[size]}`}
      style={{
        backgroundColor: `${variantStyles[variant].backgroundColor}`,
        borderColor: `${variantStyles[variant].color}50`,
        color: variantStyles[variant].color,
        boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.15)",
      }}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="toast-body d-flex align-items-center justify-content-between gap-2 flex-1 w-100 fs-6">
        <div className="d-flex align-items-center flex-1 gap-2">
          <span>{variantIcons[variant]}</span>
          <span className="flex-1">{message}</span>
        </div>
        <div>{action && <div className="me-3">{action}</div>}</div>
      </div>
      {isButtonClosed && (
        <div className="d-flex justify-content-end align-items-start pe-1 pt-1">
          <button
            type="button"
            className="btn-close btn-xs"
            onClick={onClose}
          />
        </div>
      )}
    </div>
  )
}
