import { createContext, useContext, useState } from "react"

interface RepurposalProviderTypes {
  children: React.ReactNode
}

interface RepurposalContextTypes {
  handleFeedbackSuccess: (message: string, show: boolean) => void
  feedbackSuccess: {
    show: boolean
    message: string
  }
}

export const RepurposalContext = createContext({} as RepurposalContextTypes)

export default function RepurposalProvider({
  children,
}: RepurposalProviderTypes) {
  const [feedbackSuccess, setFeedbackSuccess] = useState({
    show: false,
    message: "",
  })

  function handleFeedbackSuccess(message: string, show: boolean) {
    setFeedbackSuccess({
      show,
      message: message,
    })
  }

  return (
    <RepurposalContext.Provider
      value={{
        handleFeedbackSuccess,
        feedbackSuccess,
      }}
    >
      {children}
    </RepurposalContext.Provider>
  )
}


export const useRepurposal = () => useContext(RepurposalContext)
