import { ReactNode, useEffect, useState } from "react"
import { FilterSelect } from "../FilterTypes/FilterSelect"
import { FilterMultiSelect } from "../FilterTypes/FilterMultiSelect"
import DatePickerFilter from "../../../DatePickerFilter"
import { FilterCustomersSelect } from "../FilterTypes/FilterCustomersSelect"
import { FilterUsersSelect } from "../FilterTypes/FilterUsersSelect"
import { FilterCustomersGroupsSelect } from "../FilterTypes/FilterCustomersGroupsSelect"
import { FilterSupplierResponsibleSelect } from "../FilterTypes/FilterSupplierResponsible"
import { useFilters } from "../../../../../utils/hooks/useFilters"
import { FilterRegionsSelect } from "../FilterTypes/FilterRegionsSelect"
import { FilterStocksGroupingsSelect } from "../FilterTypes/FilterStockGroupingSelect"

export function FiltersFactor(props: FiltersFactorProps) {
  const { config, filter, setFilter, reset, setReset } = props
  const { type, ...otherConfigs } = config
  const { show } = useFilters()
  const [hasOpened, setHasOpened] = useState(false)
  const range = type === "date" ? config.range : undefined

  const filtersOptions: Record<FiltersType, ReactNode> = {
    select: (
      <FilterSelect
        labelName={config.label}
        filter={filter as Record<string, string>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string>>
          >
        }
        disabled={config.disabled}
        {...otherConfigs}
      />
    ),
    multiSelect: (
      <FilterMultiSelect
        labelName={config.label}
        filter={filter as Record<string, string[]>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string[]>>
          >
        }
        disabled={config.disabled}
        {...otherConfigs}
      />
    ),
    date: (
      <DatePickerFilter
        type={range ? "range" : "single-date"}
        filter={filter as Record<string, DateFilter>}
        disabled={config.disabled}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, DateFilter>>
          >
        }
        reset={reset}
        setReset={setReset}
        prevState={config.prevState}
        {...otherConfigs}
      />
    ),
    customersSelect: (
      <FilterCustomersSelect
        filter={filter as Record<string, string[]>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string[]>>
          >
        }
        {...otherConfigs}
      />
    ),
    stocksGroupingsSelect: (
      <FilterStocksGroupingsSelect
        filter={filter as Record<string, string[]>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string[]>>
          >
        }
        suppliersDocuments={config.suppliersDocuments}
        {...otherConfigs}
      />
    ),
    usersSelect: (
      <FilterUsersSelect
        disabled={config.disabled}
        filter={filter as Record<string, string[]>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string[]>>
          >
        }
        {...otherConfigs}
      />
    ),
    customersGroupsSelect: (
      <FilterCustomersGroupsSelect
        filter={filter as Record<string, string[]>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string[]>>
          >
        }
        {...otherConfigs}
      />
    ),
    supplierResponsibleSelect: (
      <FilterSupplierResponsibleSelect
        filter={filter as Record<string, string[]>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string[]>>
          >
        }
        disabled={config.disabled}
        {...otherConfigs}
      />
    ),
    regionsSelect: (
      <FilterRegionsSelect
        filter={filter as Record<string, string[]>}
        setFilter={
          setFilter as React.Dispatch<
            React.SetStateAction<Record<string, string[]>>
          >
        }
        disabled={config.disabled}
        {...(otherConfigs as RegionsConfig)}
      />
    ),
  }

  useEffect(() => {
    show && !hasOpened && setHasOpened(true)
  })

  return <>{hasOpened && filtersOptions[type]}</>
}
