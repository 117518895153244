import { DataPropsNewRepurposal } from "../pages/RepurposalRegister/RepurposalRegister"
import { api, errorExceptionsApi } from "./api"

interface CreateRepurposalProps {
  params: {
    stockId: string | number | null | undefined
    buyerDocument: string
    kind: string
    repurposalItemsAttributes: NewListItem[]
    buyerFreightKindId?: number
  }
}

export async function createRepurposal({ params }: CreateRepurposalProps) {
  const { data } = await api.post("/repurposals", params)
  return data
}

export const listProposal = async ({
  setData,
  id,
  setVerifyResponse,
}: {
  id: string | undefined
  setData: React.Dispatch<
    React.SetStateAction<DataPropsNewRepurposal | undefined>
  >
  setVerifyResponse: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  try {
    const response = await api.get(
      `/stocks/${id}/stock_items?context=stock_items_history`,
    )
    if (response.status !== 200) {
      setVerifyResponse(true)
    }
    setData(response.data)
  } catch (error) {
    console.error("Error fetching stock items:", error)
  }
}

export async function listState() {
  const { data } = await api.get("/states")
  return data
}

export const viewRepurposal = async (
  id: string,
  version?: string,
  context?: "contact_and_payment_type",
) => {
  const itemId = id || ""
  const response = await api.get(`/repurposals/${itemId}`, {
    params: { version, context },
  })
  return response.data
}
export const viewRepurposalCsv = async (id: string) => {
  const itemId = id || ""
  const response = await api.get(`/repurposals/${itemId}/repurposal_items/csv`)
  return response.data.repurposalItemsCsv
}

type ViewRepurposalItemsType = {
  id: string | undefined
  itemsPerPage: number
  pageNumber: number
  version?: string
}
export const viewRepurposalItems = async ({
  id,
  itemsPerPage = 10,
  pageNumber = 1,
  version,
}: ViewRepurposalItemsType) => {
  const response = await api.get(
    `/repurposals/${id}/repurposal_items/paginated`,
    {
      params: {
        pageNumber,
        itemsPerPage,
        version,
      },
    },
  )
  return response.data
}
export const viewRepurposalItemsNotPagination = async (
  id: string,
  version?: string,
) => {
  const response = await api.get(`/repurposals/${id}/repurposal_items`, {
    params: { version },
  })
  return response.data
}

export const repurposalsList = async ({
  pageNumber,
  itemsPerPage,
  supplierResponsiblesIds,
  gogreenGroupsIds,
  createdAtStartDate = "",
  createdAtFinalDate = "",
  kind = "",
  buyersDocuments = "",
  suppliersDocuments = "",
  search,
  createdByEmails = "",
  responsibleByIds = "",
  status = "",
  margin = "",
  originRegions,
  destinationRegions,
  stocksGroupings,
  ...otherProps
}: RepurposalsListTypes) => {
  const response = await api.get(`/repurposals`, {
    params: {
      pageNumber,
      itemsPerPage,
      kind,
      buyersDocuments,
      suppliersDocuments,
      margin,
      createdByEmails,
      status,
      responsibleByIds: [...(responsibleByIds ? responsibleByIds : [])],
      supplierResponsiblesIds: [
        ...(supplierResponsiblesIds ? supplierResponsiblesIds : []),
      ],
      stocksGroupings: [...(stocksGroupings ? stocksGroupings : [])],
      customersGroupIds: [...(gogreenGroupsIds ? gogreenGroupsIds : [])],
      createdAtStartDate,
      createdAtFinalDate,
      originRegions,
      destinationRegions,
      ...otherProps,
      ...search,
    },
  })
  return response.data
}

export const filterCreatedBy = async ({ search = "", pageNumber = 1 } = {}) => {
  const response = await api.get(`/repurposals/created_by_emails`, {
    params: { search, pageNumber, itemsPerPage: 10 },
  })
  return response.data
}

export const deleteRepurposal = async (coreId?: number) => {
  const response = await api.delete(`/repurposals/${coreId}`)
  return response.data
}

interface UpdateRepurposalProps {
  id: string
  params:
    | {
        stockId: string | number | null | undefined
        buyerDocument: string
        kind: string
        repurposalItemsAttributes: Array<RepurposalItem>
        code?: string
      }
    | (Partial<ViewRepurposalResponse> & { paymentTypeId?: number })
  version?: string
}

export async function updateRepurposal(props: UpdateRepurposalProps) {
  const { params, id, version } = props
  const response = version
    ? await api.put(`/repurposals/${id}`, { ...params, version })
    : await api.post(`/repurposals/${params.code}/versions`, params)
  return response.data
}

type UpdateStatusRepurposalProps = {
  id: number
  status?: string
  file?: File | null
  supplierPrice?: string
  email?: string
}

export async function updateStatusRepurposal({
  id,
  status,
  file,
  supplierPrice,
  email,
}: UpdateStatusRepurposalProps) {
  const formData = new FormData()
  if (status) {
    formData.append("status", status)
  }

  if (file) {
    formData.append("supplierProposalFile", file)
  }

  if (supplierPrice) {
    formData.append("supplierPrice", supplierPrice)
  }

  if (email) {
    formData.append("email", email)
  }
  const response = await api.patch(`/repurposals/${id}/status`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

  return response.data
}

interface SendEmailRepurposalProps {
  id: number
  emailSendTo: string
}

export async function sendEmailRepurposal({
  id,
  emailSendTo,
}: SendEmailRepurposalProps) {
  const payload = {
    emailSendTo: emailSendTo,
  }
  const response = await api.post(`repurposals/send_email/${id}}`, payload)
  response.data
}

export async function billingByRepurposal(
  code: string,
  billingKind: "invoices" | "payments" | "bills",
) {
  return await api.get(`/repurposals/${code}/billing/${billingKind}`)
}

export async function deleteRepurposalVersion(id?: number) {
  return await api.delete(`/repurposals/${id}/versions`)
}

export async function getRepurposalLogistics(id?: number) {
  return await api.get(`/repurposals/${id}/logistic`)
}

export async function updateRepurposalLogistics(id?: number) {
  return await api.patch(`repurposals/${id}/addresses`)
}

export async function updateRepurposalVehicles(id?: number) {
  return await api.patch(`/repurposals/versions/${id}/vehicles/refresh`)
}

export async function editRepurposalLogisticsDistance({
  distance,
  id,
}: {
  distance?: number
  id?: number
}) {
  return await api.patch(`/repurposals/${id}/distance`, {
    distance,
    distanceUnit: "km",
  })
}

interface submitExternalNpsProps {
  repurposalCoreCode: string
  score: number
  hashedId: string
  accessCode: string
  featureType: string
}

export async function submitExternalNps({
  repurposalCoreCode,
  score,
  hashedId,
  accessCode,
  featureType,
}: submitExternalNpsProps) {
  const payload = {
    repurposalCoreCode,
    score,
  }
  const headers = {
    hashedId,
    accessCode,
    featureType,
  }
  return await api.post(`/external/repurposals/survey_answers`, payload, {
    headers,
  })
}

export async function editRepurposalLogistics(props: {
  id?: number
  repurposalLogistics: {
    freightKindId: number
  }
}) {
  const { repurposalLogistics, id } = props
  return await api.put(
    `/repurposals/versions/${id}/logistic`,
    repurposalLogistics,
  )
}

export async function getRepurposalCores(props: GetRepurposalCoresProps) {
  return await api.get("/repurposals/cores", {
    params: {
      ...props,
      itemsPerPage: 20,
    },
  })
}

export async function getConfigsExternalRecolocation(
  hash: string,
  code: string,
) {
  const { data } = await errorExceptionsApi.get(
    "/external/external_access_controls/configs",
    {
      headers: {
        hashedId: hash,
        featureType: "buyer_repurposal",
        accessCode: code,
      },
    },
  )

  return data
}

export async function getStockItemsExternalRecolocation(
  hash: string,
  code: string,
  context: string,
) {
  const { data } = await errorExceptionsApi.get(
    `/external/stocks/stock_items` + context,
    {
      headers: {
        hashedId: hash,
        featureType: "buyer_repurposal",
        accessCode: code,
      },
    },
  )

  return data
}

export async function unbindRepurposal(id?: number) {
  return await api.delete(`/repurposals/cores/${id}/bind/`)
}

export async function bindRepurposal(
  derivatedRepurposalId?: number,
  motherRepurposalId?: number,
) {
  return await api.post(
    `/repurposals/cores/${derivatedRepurposalId}/bind/repurposal_cores/${motherRepurposalId}`,
  )
}

export async function getBoundRepurposalsInvoicesValues(id?: number) {
  return await api.get(`repurposals/cores/${id}/bound/invoices_values`)
}

export async function bindAllRepurposals(repurposalCoreId?: number) {
  return await api.post(`/repurposals/cores/${repurposalCoreId}/bind/all`)
}
