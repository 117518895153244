import { getCountryCode, getIntLocale } from "../hooks/useCountryConfigs/utils"

export function formatNameFromEmail(email: string) {
  const [username] = email.split("@")
  return username
    .split(".")
    .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
    .join(" ")
}

export function capitalizeText(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function formatPercent(
  value: number | string | undefined,
  options?: Intl.NumberFormatOptions,
) {
  value = Number(value)
  const locale = getIntLocale(getCountryCode())
  return isNaN(value)
    ? "-"
    : value.toLocaleString(locale, {
        ...options,
        style: "percent",
      })
}
