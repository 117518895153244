import { api } from "./api"

export const customersGroupsList = async (props: customersGroupsListProps) => {
  const { page = 1, ...otherProps } = props

  return api.get("customers_groups", {
    params: {
      pageNumber: page,
      ...otherProps,
    },
  })
}

export const registerCustomersGroup = async (
  props: registerCustomersGroupProps,
) => {
  const { name, customerIds, id = null } = props

  return await api(`/customers_groups/${id || ""}`, {
    method: id ? "PUT" : "POST",
    data: { name, customerIds },
  })
}

export const customersInAGroupList = async (id: number) => {
  return api.get(`/customers_groups/${id}`)
}

export const deleteCustomersGroup = async (
  id: number | undefined = undefined,
) => {
  return api.delete(`/customers_groups/${id}`)
}
