import { Button } from "react-bootstrap"
import { useFilters } from "../../../../../utils/hooks/useFilters"
import { useTranslation } from "react-i18next"

export function NewFilterButton(props: { "data-testid"?: string }) {
  const { show, setShow, hasAppliedFilters } = useFilters()
  const { t } = useTranslation()

  return (
    <Button
      variant={hasAppliedFilters ? "primary" : "outline-primary"}
      onClick={() => setShow(!show)}
      data-testid={`${props["data-testid"]}OpenFilter`}
    >
      {t("Global.attributes.filter")}
    </Button>
  )
}
