import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { viewRepurposal } from "../../../services/repurposal.service"
import { useQuery } from "@tanstack/react-query"
import {
  billReduce,
  getBilling,
  invoiceReduce,
  paymentReduce,
} from "./utils/useBillingReduces"
import { setApiErrorMessages } from "../../setApiErrorMessage"

const BillingContext = createContext<BillingProps | undefined>(undefined)

export const BillingProvider = ({ children }: React.PropsWithChildren) => {
  const [isShowing, setIsShowing] = useState(false)
  const [id, setId] = useState<string>()
  const [invoicedRepurposal, setInvoicedRepurposal] =
    useState<ViewRepurposalResponse>()
  const [invoicedRepurposalIsLoading, setInvoicedRepurposalIsLoading] =
    useState(false)

  const baseQueryKey = ["repurposal", { repurposalId: id }]
  const invoicesQueryKey = [...baseQueryKey, "invoice"]
  const paymentsQueryKey = [...baseQueryKey, "payments"]
  const billsQueryKey = [...baseQueryKey, "bills"]

  const {
    data: invoices,
    isFetching: invoiceIsLoading,
    error: invoicesError,
  } = useQuery({
    queryKey: invoicesQueryKey,
    queryFn: () => getBilling("invoices", id, invoiceReduce),
    enabled: !!id && isShowing,
  })

  const {
    data: payments,
    isFetching: paymentsIsLoading,
    error: paymentsError,
  } = useQuery({
    queryKey: paymentsQueryKey,
    queryFn: () => getBilling("payments", id, paymentReduce),
    enabled: !!id && isShowing,
  })

  const {
    data: bills,
    isFetching: billsIsLoading,
    error: billsError,
  } = useQuery({
    queryKey: billsQueryKey,
    queryFn: () => getBilling("bills", id, billReduce),
    enabled: !!id && isShowing,
  })

  const value = useMemo<BillingProps>(() => {
    return {
      invoices: {
        ...invoices,
        isLoading: invoiceIsLoading,
        queryKey: invoicesQueryKey,
      },
      payments: {
        ...payments,
        isLoading: paymentsIsLoading,
        queryKey: paymentsQueryKey,
      },
      bills: {
        ...bills,
        isLoading: billsIsLoading,
        queryKey: billsQueryKey,
      },
      invoicedRepurposal: {
        isLoading: invoicedRepurposalIsLoading,
        get: getInvoicedRepurposal,
        set: setInvoicedRepurposal,
      },
      setIsShowing,
      setId,
    }
  }, [
    invoices,
    invoiceIsLoading,
    payments,
    paymentsIsLoading,
    bills,
    billsIsLoading,
    invoicedRepurposal,
    invoicedRepurposalIsLoading,
    baseQueryKey,
    setIsShowing,
    setId,
  ])

  useEffect(() => {
    invoicesError && setApiErrorMessages(invoicesError)
    paymentsError && setApiErrorMessages(paymentsError)
    billsError && setApiErrorMessages(billsError)
  }, [invoicesError, paymentsError, billsError])

  async function getInvoicedRepurposal(code: string) {
    if (code === invoicedRepurposal?.code) return invoicedRepurposal
    else {
      setInvoicedRepurposalIsLoading(true)
      try {
        const data = await viewRepurposal(code, "invoiced")
        setInvoicedRepurposal(data)
        return data
      } catch (error) {
        console.error(error)
      } finally {
        setInvoicedRepurposalIsLoading(false)
      }
    }
  }

  return (
    <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
  )
}

export function useBilling(): BillingProps {
  const context = useContext(BillingContext)
  if (context === undefined) {
    throw new Error("useIntLanguagemust be used within a IntLanguageProvider")
  }
  return context
}
