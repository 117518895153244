type AuthTokensManager = {
  getAccessToken: () => string | null
  getClient: () => string | null
  getExpirity: () => string | null
  getUid: () => string | null
  setTokensByParams: () => void
  cleanTokens: () => void
  tokensExist: () => boolean
}

export const authTokens: AuthTokensManager = {
  getAccessToken: () => `${localStorage.getItem("access-token")}`,
  getClient: () => `${localStorage.getItem("client")}`,
  getExpirity: () => `${localStorage.getItem("expirity")}`,
  getUid: () => `${localStorage.getItem("uid")}`,

  setTokensByParams: () => {
    const params = new URLSearchParams(window.location.search)
    const accessToken = params.get("auth_token")
    const client = params.get("client_id")
    const expirity = params.get("expiry")
    const uid = params.get("uid")

    if (!accessToken || !client || !expirity || !uid) return

    localStorage.setItem("access-token", accessToken)
    localStorage.setItem("client", client)
    localStorage.setItem("expirity", expirity)
    localStorage.setItem("uid", uid)
    window.location.replace(
      `${window.location.origin}${window.location.pathname}`,
    )
  },

  tokensExist: function () {
    return (
      this.getAccessToken() !== "null" &&
      this.getClient() !== "null" &&
      this.getExpirity() !== "null" &&
      this.getUid() !== "null"
    )
  },

  cleanTokens: () => {
    localStorage.removeItem("access-token")
    localStorage.removeItem("client")
    localStorage.removeItem("expirity")
    localStorage.removeItem("uid")
  },
}
