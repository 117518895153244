 
import { atom } from "recoil"

export const modalCadastrarEditarNF = atom({
  key: "modalCadastrarEditarNF",
  default: false,
})

export const atomInvoiceNumber = atom({
  key: "atomInvoiceNumber",
  default: "",
})
