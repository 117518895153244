import { Col, Container, Nav, Row } from "react-bootstrap"
import { BsBoxArrowRight } from "react-icons/bs"
import { useNavigate } from "react-router-dom"
import logo from "../../assets/img/logo_recoloca.svg"
import { ErrorTemplateProps } from "./ErrorTemplate"
import { HttpStatusCode } from "axios"
import { authTokens } from "../../utils/authTokens"
import { useAuth } from "../../utils/hooks/useAuth"

export function ErrorTemplate(props: ErrorTemplateProps) {
  const {
    errorType,
    errorDescription,
    errorTitle,
    errorSolution,
    header = true,
  } = props
  const navigate = useNavigate()
  const { signOut } = useAuth()

  const handleLogout = async () => {
    authTokens.cleanTokens()
    await signOut()
    navigate("/")
  }

  return (
    <>
      {header && (
        <Container className="p-3 bg-dark text-white " fluid>
          <Container>
            <Row>
              <Col className="d-flex align-items-center">
                <Nav.Link
                  href="/"
                  className="text-decoration-none my-2 
              my-lg-0 me-lg-auto"
                >
                  <img src={logo} width="180" />
                </Nav.Link>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <Nav.Link
                  onClick={handleLogout}
                  style={{ color: "#fff" }}
                  className="d-flex flex-column align-items-center"
                >
                  <BsBoxArrowRight size={25} />
                  <span>Sair</span>
                </Nav.Link>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      <div className="pt-5 mt-5 d-flex flex-column align-items-center">
        <p
          className="display-1 fw-bolder pt-5"
          data-testid={`${props["data-testid"]}-code`}
        >
          {HttpStatusCode[errorType]}
        </p>
        <p
          className=" fs-2 fw-bold"
          data-testid={`${props["data-testid"]}-title`}
        >
          {errorTitle}
        </p>
        <p
          className=" fs-5 pb-4"
          data-testid={`${props["data-testid"]}-description`}
        >
          {errorDescription}
        </p>
        <p data-testid={`${props["data-testid"]}-solution`}>{errorSolution}</p>
      </div>
    </>
  )
}
