import { endOfDay, startOfDay } from "date-fns"
import { t } from "i18next"
import { useEffect, useRef, useState } from "react"
import { Dropdown, Form } from "react-bootstrap"
import { DateRange, DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"
import { useCountryConfigs } from "../../../utils/hooks/useCountryConfigs"

import DatePickerFilterPresets from "./components/DatePickerFilterPresets"
import {
  datetimeToDate,
  intlFormatDate,
  toSelectedTimezone,
} from "../../../utils/timezoneHelpers"

export default function DatePickerFilter(props: DatePickerInterfaceProps) {
  const {
    type,
    reset,
    setReset,
    setDate,
    filter,
    setFilter,
    filterName,
    label,
    prevState,
    footer,
    withPresets = true,
    onlyDate = false,
  } = props as DatePickerComponentProps

  const actualYear = new Date().getFullYear()
  const startFilter =
    filter && filterName ? (filter[filterName] as DateFilter) : undefined

  const { dateLocale } = useCountryConfigs()

  const toggleRef = useRef<HTMLButtonElement | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    startFilter?.initial ? new Date(`${startFilter?.initial}`) : new Date(),
  )
  const [selectedDateRange, setSelectedDateRange] = useState<
    DateRange | undefined
  >(
    prevState
      ? {
          from: new Date(prevState.initial),
          to: new Date(prevState.final),
        }
      : startFilter
        ? {
            from: new Date(`${startFilter.initial}`),
            to: new Date(`${startFilter.final}`),
          }
        : undefined,
  )

  const formatDate = (date: Date) => {
    return intlFormatDate(date)
  }

  function handleSelectedDate(date: Date | undefined | DateRange) {
    let initial: Date | null = null
    let final: Date | null = null
    if (date instanceof Date) {
      setSelectedDate(date)
      initial = date
    } else {
      setSelectedDateRange(date)
      if (date?.from) {
        initial = date.from
        final = date?.to ? date.to : initial
      }
    }

    const selectedDates = onlyDate
      ? {
          initial: initial ? datetimeToDate(initial) : "",
          final: final ? datetimeToDate(final) : "",
        }
      : {
          initial: initial
            ? toSelectedTimezone(startOfDay(initial)).toISOString()
            : "",
          final: final ? toSelectedTimezone(endOfDay(final)).toISOString() : "",
        }

    setFilter &&
      filterName &&
      setFilter((prev) => ({
        ...prev,
        [filterName]: selectedDates,
      }))
    setDate && setDate(selectedDates)
  }

  useEffect(() => {
    if (reset) {
      setDate && setDate({ initial: "", final: "" })
      setSelectedDate(undefined)
      setSelectedDateRange(undefined)
    }
    setReset(false)
  }, [reset])

  const formatDateSingleDate = () => {
    return selectedDate ? formatDate(selectedDate) : t("Global.attributes.all")
  }

  const formatRangeDate = () => {
    let formatedDateString = ""
    if (selectedDateRange?.from) {
      const fromDateString = formatDate(selectedDateRange.from)
      const toDateString = selectedDateRange.to
        ? formatDate(selectedDateRange.to)
        : ""
      formatedDateString = `${fromDateString} - ${toDateString}`
    } else {
      formatedDateString = t("Global.attributes.all")
    }

    return formatedDateString
  }

  const formatedDate = () => {
    let formatedDateString = ""
    switch (type) {
      case "single-date":
        formatedDateString = formatDateSingleDate()
        break
      case "range":
        formatedDateString = formatRangeDate()
        break
      case "datetime":
        break
      default:
        break
    }
    return formatedDateString
  }

  return (
    <Form.Group className={label ? "mt-3" : ""}>
      {label ? (
        <Form.Label
          className="limite-caracteres-label mb-0"
          data-testid={`${filterName}Label`}
          onClick={() => toggleRef.current?.click()}
        >
          {label}
        </Form.Label>
      ) : null}
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-secondary"
          className="dropdown-input-button bg-white text-dark w-100 d-flex justify-content-between focus-ring form-select border"
          data-testid={`${filterName}FilterToggle`}
          ref={toggleRef}
        >
          <span className="text-truncate">{formatedDate()}</span>
        </Dropdown.Toggle>
        <Dropdown.Menu data-testid={`${filterName}FilterContent`}>
          {type === "single-date" ? (
            <>
              <Dropdown.Header className="text-dark fs-6">
                <DayPicker
                  mode="single"
                  selected={selectedDate}
                  onSelect={(date) => handleSelectedDate(date)}
                  captionLayout="dropdown-buttons"
                  fromYear={2023}
                  toYear={Number(actualYear)}
                  locale={dateLocale}
                  footer={footer}
                />
              </Dropdown.Header>
            </>
          ) : (
            <>
              <Dropdown.Header
                className="text-dark fs-6 d-flex"
                data-testid={`${filterName}DayPicker`}
              >
                {withPresets && (
                  <DatePickerFilterPresets
                    handleSelectedDate={handleSelectedDate}
                  />
                )}
                <DayPicker
                  mode="range"
                  selected={selectedDateRange}
                  onSelect={(date) => handleSelectedDate(date)}
                  captionLayout="dropdown-buttons"
                  fromYear={2023}
                  toYear={Number(actualYear)}
                  locale={dateLocale}
                  footer={footer}
                />
              </Dropdown.Header>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </Form.Group>
  )
}
