 
export const noPhoto =
  "https://arquivosrecoloca.blob.core.windows.net/imagens/no-photo.jpg?timestamp=1683064669552"
export const semPhoto = "./../../../assets/imgs/no-photo.jpg"

export const BASE_URL = import.meta.env.VITE_BASEURL as string
export const BASE_URL_RUBY = import.meta.env.VITE_BASEURL_RUBY as string

export const azureNoPhoto =
  "https://arquivosrecoloca.blob.core.windows.net/imagens/no-photo.jpg"
export const assestsNoPhoto = "./../../../assets/imgs/no-photo.jpg"

export const SUPPORT_LINK =
  "https://api.whatsapp.com/send/?phone=551140036541&text=Ol%C3%A1%21+venho+atrav%C3%A9s+da+Recoloca+e+gostaria+de+solucionar+uma+d%C3%BAvida.&type=phone_number&app_absent=0"
