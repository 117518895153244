import React, { ReactNode, useCallback, useEffect, useState } from "react"
import ToastContext, {
  ShowToastOptions,
  ToastSize,
  ToastVariant,
} from "../context/ToastContext"
import Toast from "../components/Toaster"

interface ToastMessage {
  id: number
  message: string
  duration: number
  action?: ReactNode
  variant: ToastVariant
  isButtonClosed?: boolean
  size: ToastSize
}

const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([])
  const [position, setPosition] = useState<string>("bottom-right")
  const [timeouts, setTimeouts] = useState<Map<number, NodeJS.Timeout>>(
    new Map(),
  )

  const showToast = useCallback(
    (
      variant: ToastVariant,
      message: string,
      {
        duration = 5000,
        action,
        position: customPosition,
        size = "lg",
        isButtonClosed = true,
      }: ShowToastOptions = {},
    ) => {
      const id = new Date().getTime()
      setPosition(customPosition ?? position)
      setToasts((prevToasts) => [
        ...prevToasts,
        { id, variant, message, duration, action, size, isButtonClosed },
      ])

      const timeoutId = setTimeout(() => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
      }, duration)

      setTimeouts((prevTimeouts) => new Map(prevTimeouts).set(id, timeoutId))
    },
    [position],
  )

  const success = (message: string, options?: ShowToastOptions) =>
    showToast("success", message, options)
  const danger = (message: string, options?: ShowToastOptions) =>
    showToast("danger", message, options)
  const warning = (message: string, options?: ShowToastOptions) =>
    showToast("warning", message, options)
  const info = (message: string, options?: ShowToastOptions) =>
    showToast("info", message, options)
  const secondary = (message: string, options?: ShowToastOptions) =>
    showToast("secondary", message, options)

  const positionClass = {
    "top-right": "position-fixed top-0 end-0 p-3 cursor-pointer",
    "top-left": "position-fixed top-0 start-0 p-3 cursor-pointer",
    "bottom-right": "position-fixed bottom-0 end-0 p-3 cursor-pointer",
    "bottom-left": "position-fixed bottom-0 start-0 p-3 cursor-pointer",
    "top-center":
      "position-fixed top-0 start-50 translate-middle-x p-3 cursor-pointer",
    "bottom-center":
      "position-fixed bottom-0 start-50 translate-middle-x p-3 cursor-pointer",
  }[position]

  const handleMouseEnter = (id: number) => {
    const timeoutId = timeouts.get(id)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  }

  const handleMouseLeave = (id: number, duration: number) => {
    const timeoutId = setTimeout(() => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
    }, duration)
    setTimeouts((prevTimeouts) => new Map(prevTimeouts).set(id, timeoutId))
  }

  const handleClose = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id))
    const timeoutId = timeouts.get(id)
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  }

  useEffect(() => {
    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
  }, [timeouts])

  return (
    <ToastContext.Provider
      value={{ success, danger, warning, info, secondary }}
    >
      {children}
      <div className={positionClass} style={{ zIndex: 9999 }}>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            variant={toast.variant}
            message={toast.message}
            action={toast.action}
            size={toast.size}
            isButtonClosed={toast.isButtonClosed}
            onMouseEnter={() => handleMouseEnter(toast.id)}
            onMouseLeave={() => handleMouseLeave(toast.id, toast.duration)}
            onClose={() => handleClose(toast.id)}
          />
        ))}
      </div>
    </ToastContext.Provider>
  )
}

export default ToastProvider
