import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

// Import das traduções feitas em um arquivo .json
import enJson from "./translations/en.json"
import ptBRJson from "./translations/ptBR.json"
import esJson from "./translations/es.json"

i18n
  .use(LanguageDetector) // Esse plugin ele verifica o idioma padrão do navegador do usuário
  .use(initReactI18next) // Disponibiliza todos os componentes através do Context-API
  .init({
    fallbackLng: "ptBR", // Linguagem padrão
    interpolation: {
      escapeValue: false,
    },
    resources: {
      // Todas as traduções que a aplicação terá
      en: enJson,
      ptBR: ptBRJson,
      es: esJson,
    },
  })

export default i18n
