import { atom } from "recoil"

export const atomIsEditMode = atom<boolean | undefined>({
  key: "atomIsEditMode",
  default: undefined,
})
export const atomUpdateListCustomers = atom({
  key: "atomUpdateListCustomers",
  default: false,
})
export const atomShowModalDelete = atom({
  key: "atomShowModalDelete",
  default: false,
})
export const atomShippingTypes = atom<Array<number> | null>({
  key: "atomShippingType",
  default: null,
})
export const atomMinimumPrice = atom({
  key: "atomMinimumPrice",
  default: "0",
})
export const atomMinimumWeight = atom({
  key: "atomMinimumWeight",
  default: "",
})
export const atomMinimumPallet = atom({
  key: "atomMinimumPallet",
  default: "",
})
export const atomSelectedStates = atom<string[]>({
  key: "atomSelectedStates",
  default: [],
})
export const atomCode = atom({
  key: "atomCode",
  default: "",
})
export const atomIdDistributionCenter = atom({
  key: "atomIdDistributionCenter",
  default: 0,
})
export const atomStatesServed = atom({
  key: "atomStatesServed",
  default: [''],
})
export const atomIsEditModeDistributionCenter = atom({
  key: "atomIsEditModeDistributionCenter",
  default: false,
})
export const atomDistributionCenterName = atom({
  key: "atomDistributionCenterName",
  default: "",
})
