import { countryCodeExceptionsApi } from "./api"

export async function getUsersList(props: GetUserListProps) {
  const { page = 1, kind = "external", ...otherProps } = props
  return await countryCodeExceptionsApi.get("/auth/users", {
    params: {
      ...otherProps,
      pageNumber: page,
      kind,
    },
  })
}

export function createExternalUser(props: createExternalUserProps) {
  const { email, password = "", passwordConfirmation = "" } = props
  return countryCodeExceptionsApi.post("/auth", {
    email,
    password,
    passwordConfirmation,
  })
}

export function updateExternalUser(props: updateExternalUserProps) {
  const { email, password = null, passwordConfirmation = null, id } = props
  return countryCodeExceptionsApi.put(`/auth/users/${id}`, {
    email,
    password,
    passwordConfirmation,
  })
}

export function deleteExternalUser(id: number | undefined = undefined) {
  return countryCodeExceptionsApi.delete(`/auth/users/${id}`)
}
