import { countries } from "countries-list"
import { FC, Fragment } from "react"
import { Dropdown } from "react-bootstrap"
import { countryConfigurations } from "../../../configs/countryConfig"
import { useCountryConfigs } from "../../../utils/hooks/useCountryConfigs"
import { CustomDropdown } from "../../Customs/CustomDropdown"
import { getOffset } from "../../../utils/timezoneHelpers"

const CountryDropdown: FC = () => {
    const {
        countryCode: [countryCode, setCountryCode],
    } = useCountryConfigs()
    
    const avaliableCountries = JSON.parse(
        import.meta.env.VITE_AVAILABLE_COUNTRIES.replace(/'/g, '"'),
    )

    const currentOffsetName = getOffset(countryConfigurations[countryCode].timezone.name)

    return (
        <>
            <CustomDropdown
                name={`${countries[countryCode]?.native} (${currentOffsetName}) ` }
                variant="outline-light"
                className=" btn-sm ms-3"
                dropdownClassName="d-flex align-items-center"
                data-testid="countryDropdown"
            >
                {avaliableCountries.map((item: string) => {
                    const country = item as keyof typeof countries
                    const offsetName = getOffset(countryConfigurations[country].timezone.name)
                    return (
                        <Fragment key={country}>
                            {countryCode !== country && (
                                <Dropdown.Item
                                    onClick={() => {
                                        setCountryCode(country)
                                    }}
                                    data-testid={`countryDropdownItem${country}`}
                                >
                                    {countries[country].native} ({offsetName})
                                </Dropdown.Item>
                            )}
                        </Fragment>
                    )
                })}
            </CustomDropdown>
        </>
    )
}

export { CountryDropdown }
