import { api } from "./api"

export async function regionsList(props: {
  pageNumber: number
  search: string
}) {
  return api.get("/regions", {
    params: { ...props, itemsPerPage: 20 },
  })
}
