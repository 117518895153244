import { FilterMultiSelect } from "../FilterMultiSelect"
import { customersList } from "../../../../../../services/customer.service"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../../../utils/hooks/useCountryConfigs"

function defaultRenderValue(item: IResponse) {
  return item?.id
}

function defaultRenderName(item: IResponse) {
  return item?.name
}

function getNextPageParam(lastPage: SimpleIndexCustomerQuery) {
  return lastPage?.page + 1
}

export function FilterCustomersSelect(props: FilterCustomersSelectProps) {
  const {
    customersBond,
    removeScroll,
    removeSearch,
    label,
    renderValue = defaultRenderValue,
    renderName = defaultRenderName,
    ...otherConfigs
  } = props
  const [search, setSearch] = useDebounceValue("", 500)
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  async function getCustomers({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexCustomerQuery> {
    const { data } = await customersList({
      search,
      context: "simple_index",
      bond: customersBond,
      pageNumber: pageParam,
    })
    return { customers: data.customers, page: pageParam, count: data.count }
  }

  const query = useInfiniteQuery({
    queryKey: ["customers", "simple_index", customersBond, search, countryCode],
    queryFn: getCustomers,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 5 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  function handleScroll() {
    query.fetchNextPage()
  }

  return (
    <>
      {FilterMultiSelect<IResponse>({
        renderName,
        renderValue,
        disabled: props.disabled,
        onClick: props.onClick,
        options: query.data?.pages.flatMap((page) => page.customers),
        isLoading: query.isLoading,
        onChange: !removeSearch ? handleSearch : undefined,
        onScroll: !removeScroll ? handleScroll : undefined,
        count: query.data?.pages.slice(-1)[0].count,
        labelName: label,
        ...otherConfigs,
      })}
    </>
  )
}
