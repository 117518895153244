import { Outlet, useLocation } from "react-router-dom"
import { Header } from "../components/Header"
import { useAuth } from "../utils/hooks/useAuth"
import { FiltersProvider } from "../utils/hooks/useFilters"
import { useRecoilValue } from "recoil"
import { atomPageBodyKey } from "../recoil/atom"
import { useRecoilState } from "recoil"
import { atomShow404Page } from "../recoil/atom"
import { Default404Page } from "../components/Default404Page"
import { useEffect } from "react"

export function DefaultLayout() {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const pageBodyKey = useRecoilValue(atomPageBodyKey)
  const [show404Page, setShow404Page] = useRecoilState(atomShow404Page)

  useEffect(() => {
    setShow404Page(false)
  }, [location.pathname])

  if (!isAuthenticated) {
    return null
  }

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Header />
      {show404Page ? (
        <Default404Page />
      ) : (
        <FiltersProvider key={location.pathname}>
          <Outlet key={pageBodyKey} />
        </FiltersProvider>
      )}
    </div>
  )
}
