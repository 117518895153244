import { captureException } from "@sentry/react"
import { AxiosError } from "axios"

export function setApiErrorMessages(
  error: unknown,
  setErrorMessages?: React.Dispatch<React.SetStateAction<Array<string>>>,
) {
  console.error(error)
  captureException(error)
  error instanceof AxiosError &&
    error.response?.data.messages &&
    setErrorMessages?.([...error.response.data.messages])
}
