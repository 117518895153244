import { Trans, useTranslation } from "react-i18next"
import { ErrorTemplate } from "../ErrorTemplate"
import { capitalizeText } from "../../utils/Format"
import { CustomLink } from "../Customs/CustomLink"

export function Default404Page() {
  const { t } = useTranslation()

  return (
    <ErrorTemplate
      errorType={"NotFound"}
      errorTitle={t("Page404.attributes.errorOccurred")}
      errorDescription={t("Page404.attributes.pageNotFound")}
      errorSolution={
        <Trans
          values={{
            recolocations: capitalizeText(t("Repurposal.repurposal.other")),
            billing: capitalizeText(t("Billing.financial")),
          }}
          i18nKey={"Page404.attributes.navigateSolution"}
        >
          ...
          <CustomLink
            className="d-inline"
            href="/recolocacoes"
            data-testid="404-error-recolocation-link"
          >
            ...
          </CustomLink>
          ...
          <CustomLink
            className="d-inline"
            href="/financial/invoices"
            data-testid="404-error-billing-link"
          >
            ...
          </CustomLink>
        </Trans>
      }
      header={false}
      data-testid="404-error"
    />
  )
}
