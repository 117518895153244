/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap"
import { CustomAlert } from "../Customs/CustomAlert"
import { useExternalRecolocation } from "../../utils/hooks/useExternalRecolocation"
import { useParams } from "react-router-dom"
import { t } from "i18next"
import {
  CountryCode,
  useCountryConfigs,
} from "../../utils/hooks/useCountryConfigs"
import * as Sentry from "@sentry/browser"

interface PrivateProps {
  children: ReactNode
}

export function PrivateIsBuyerRepurposal({
  children,
}: PrivateProps): JSX.Element {
  const { id, country } = useParams()
  const [isActive, setIsActive] = useState(false)
  const {
    countryCode: [, setCountryCode],
  } = useCountryConfigs()
  const [isError, setIsError] = useState(false)
  const [inputValues, setInputValues] = useState(["", "", "", ""])
  const [isDisabled, setIsDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const { externalRecolocation, messageFeedback } = useExternalRecolocation()

  const inputRefs: React.MutableRefObject<HTMLInputElement | any>[] =
    Array.from({ length: 4 }, () => useRef<HTMLInputElement | any>())

  useEffect(() => {
    const countryCode: CountryCode = (country as CountryCode) ?? "BR"
    countryCode && setCountryCode(countryCode)
  }, [country])

  const handleInputChange = (
    index: number,
    e: ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = e.target.value

    if (/^[0-9a-zA-Z]$/.test(value) || value === "") {
      const newInputValues = [...inputValues]
      newInputValues[index] = value
      setInputValues(newInputValues)

      if (value.length === 1 && index < 3) {
        inputRefs[index + 1]?.current?.focus()
      }
    }
  }

  const handleBackspace = (
    index: number,
    e: KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (index > 0 && inputRefs[index]?.current && e.key === "Backspace") {
      const newInputValues = [...inputValues]
      newInputValues[index] = ""
      setInputValues(newInputValues)
      inputRefs[index - 1]?.current?.focus()
    } else if (index === 0 && e.key === "Backspace") {
      const newInputValues = [...inputValues]
      newInputValues[index] = ""
      setInputValues(newInputValues)
    }
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const clipboardData = e.clipboardData.getData("text")
    const characters = clipboardData.replace(/\s/g, "").split("").slice(0, 4)
    const newInputValues = [...inputValues]

    for (let i = 0; i < characters.length; i++) {
      if (
        inputRefs[i] &&
        inputRefs[i].current &&
        /^[0-9a-zA-Z]$/.test(characters[i])
      ) {
        newInputValues[i] = characters[i]
  
        inputRefs[i].current.value = characters[i]
      }
    }

    setInputValues(newInputValues)
  }

  const handlePasteFirstInput = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault()
    const clipboardData = e.clipboardData.getData("text")
    const characters = clipboardData.replace(/\s/g, "").split("").slice(0, 4)
    const newInputValues = [...inputValues]

    for (let i = 0; i < characters.length; i++) {
      if (
        inputRefs[i] &&
        inputRefs[i].current &&
        /^[0-9a-zA-Z]$/.test(characters[i])
      ) {
        newInputValues[i] = characters[i]

        inputRefs[i].current.value = characters[i]
      }
    }

    setInputValues(newInputValues)
  }

  const delay = (amount = 750) =>
    new Promise((resolve) => setTimeout(resolve, amount))

  const verify = async () => {
    const value = inputValues.join("")
    if (value.length !== 4) {
      setIsDisabled(true)
    }
    try {
      setLoading(true)
      const response = await externalRecolocation({
        code: value,
        hash: id as string,
      })

      if (response) {
        setIsActive(true)
      }

      await delay()
    } catch (error) {
      setIsError(true)
      Sentry.captureException(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setLoading(true)
      verify()
    } catch (err) {
      console.error(err)
    }
  }

  if (!isActive) {
    return (
      <Form onSubmit={handleSubmit}>
        <div
          className="d-flex justify-content-center align-items-center bg-secondary bg-opacity-50"
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgb(83, 95, 88)",
          }}
        >
          <Card style={{ width: "25rem", padding: "1rem", zIndex: "50" }}>
            <Card.Img
              className="px-2"
              variant="top"
              src="/images/LOGO_ESCURA.svg"
              style={{ width: "180px" }}
            />
            <Card.Body className="p-2 m-2">
              <CustomAlert
                show={isError}
                onClose={() => setIsError(false)}
                variant="danger"
              >
                <span dangerouslySetInnerHTML={{ __html: messageFeedback }} />
              </CustomAlert>
              <Card.Text>
                {t("Repurposal.attributes.enterAccessCode")}
              </Card.Text>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex gap-2">
                  {inputRefs.map((ref, index) => (
                    <div className="d-flex gap-2 w-100" key={index}>
                      <InputGroup className=" w-25 w-100">
                        <Form.Control
                          data-testid={`input-${index}`}
                          className="py-3 text-center fs-3 text-uppercase "
                          ref={ref}
                          value={inputValues[index]}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(index, e)
                          }
                          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
                            handleBackspace(index, e)
                          }
                          onPaste={(e: ClipboardEvent<HTMLInputElement>) =>
                            index === 0
                              ? handlePasteFirstInput(e)
                              : handlePaste(e)
                          }
                        />
                      </InputGroup>
                    </div>
                  ))}
                </div>
                {isDisabled && (
                  <span className="text-danger">
                    {t("Global.alerts.requiredField")}
                  </span>
                )}
              </div>
              <Button
                type="submit"
                variant="success"
                disabled={
                  inputValues.some((value) => value.length !== 1) || loading
                }
                className="w-100 mt-4"
                data-testid="accessButton"
              >
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  t("Repurposal.attributes.accessOpportunities")
                )}
              </Button>
            </Card.Body>
          </Card>
        </div>
      </Form>
    )
  }
  return <>{children}</>
}

export default PrivateIsBuyerRepurposal
