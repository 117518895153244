import { atom } from "recoil"

export const atomCustomersGroupName = atom({
  key: "atomCustomersGroupName",
  default: "",
})
export const atomCustomersGroupId = atom<number | undefined>({
  key: "atomCustomersGroupId",
  default: undefined,
})
export const atomCustomers = atom<Array<number>>({
  key: "atomCustomers",
  default: [],
})
