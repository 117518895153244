import { FilterMultiSelect } from "../FilterMultiSelect"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../../../utils/hooks/useCountryConfigs"
import { api } from "../../../../../../services/api"
import { useEffect, useState } from "react"
import { t } from "i18next"

function defaultRenderValue(item: IResponse) {
  return item?.id
}

function defaultRenderName(item: IResponse) {
  return item?.name
}

function getNextPageParam(lastPage: SimpleIndexStockGroupingQuery) {
  return lastPage?.page + 1
}

export function FilterStocksGroupingsSelect(
  props: FilterStockGroupingsSelectProps,
) {
  const {
    removeScroll,
    removeSearch,
    label,
    renderValue = defaultRenderValue,
    renderName = defaultRenderName,
    suppliersDocuments,
    ...otherConfigs
  } = props

  const [search, setSearch] = useDebounceValue("", 500)
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    if (suppliersDocuments) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }, [suppliersDocuments])

  async function getStocksGroupings({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SimpleIndexStockGroupingQuery> {
    const { data } = await api.get("stocks/stock_groupings", {
      params: {
        name: search,
        itemsPerPage: 20,
        suppliersDocuments: suppliersDocuments,
        pageNumber: pageParam,
      },
    })
    return {
      stocksGroupings: data.stocksGroupings,
      page: pageParam,
      count: data.count,
    }
  }

  const query = useInfiniteQuery({
    queryKey: ["stocksGroupings", search, countryCode, suppliersDocuments],
    queryFn: getStocksGroupings,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 5 * 60 * 1000,
    enabled: enabled,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  function handleScroll() {
    query.fetchNextPage()
  }

  return (
    <>
      {FilterMultiSelect<IResponse>({
        renderName,
        renderValue,
        info: t("RepurposalView.alerts.warning.supplierMustBeSelected")!,
        disabled: props.disabled,
        onClick: props.onClick,
        options: query.data?.pages.flatMap((page) => page.stocksGroupings),
        isLoading: query.isLoading,
        onChange: !removeSearch ? handleSearch : undefined,
        onScroll: !removeScroll ? handleScroll : undefined,
        count: query.data?.pages.slice(-1)[0].count,
        labelName: label,
        ...otherConfigs,
      })}
    </>
  )
}
