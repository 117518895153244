export const FREIGHT_TYPES = {
  CIF: 1,
  FOB: 2,
}

export const FREIGHT_IDS = Object.entries(FREIGHT_TYPES).reduce(
  (freightIds: Record<number, FreightKinds>, [key, value]) => {
    freightIds[value] = key as FreightKinds
    return freightIds
  },
  {},
)
