import { Form } from "react-bootstrap"
import { DropdownInput } from "../../../../../DropdownInput"

export function FilterSelect<T extends Record<string, string>>({
  labelName,
  filterName,
  options,
  filter,
  setFilter,
  renderName = (item: T) => ("name" in item ? item["name"] : ""),
  renderValue = (item?: T) => (item && "id" in item ? item["id"] : ""),
  onChange,
  onScroll,
  isLoading,
  allOption,
  count,
  disabled,
}: FilterInputProps<T>) {
  function handleItemClick(selectedItem: T) {
    setFilter((prevState) => {
      return { ...prevState, [filterName]: renderValue(selectedItem) }
    })
  }
  return (
    <Form.Group className="mb-3 mt-3" controlId={filterName}>
      <Form.Label
        className="limite-caracteres-label mb-0"
        data-testid={`${filterName}Label`}
      >
        {labelName}
      </Form.Label>
      <DropdownInput
        onClick={handleItemClick as (item: T | undefined) => void}
        options={options ?? []}
        renderName={renderName}
        disabled={disabled}
        value={
          filter[filterName]
            ? options?.find((item) => renderValue(item) === filter[filterName])
            : undefined
        }
        onChange={onChange}
        {...(onScroll && count
          ? {
              onScroll,
              count,
            }
          : {})}
        isLoading={isLoading}
        id={filterName}
        deselectable
        allOption={allOption}
      />
    </Form.Group>
  )
}
