import { useNavigate } from "react-router-dom"

export function NotFound() {
  const navigate = useNavigate()

  return (
    <div className="row d-flex flex-column mt-5 text-dark h-100 pt-5 align-items-center text-center">
      <div className="col mb-5">
        <h1 className="display-1 fw-bolder">404</h1>
        <h1 className="fs-2 fw-bold">Ocorreu um erro</h1>
      </div>

      <span className="fs-5">
        Não encontramos a página que você está procurando.
      </span>

      <div className="mt-5">
        <span className="d-flex items-center justify-content-center gap-2">
          Tente navegar pelas páginas de
          <a
            onClick={() => navigate("/estoque")}
            target="_blank"
            rel="noreferrer"
          >
            Estoque
          </a>
          ou
          <a
            onClick={() => navigate("/catalogs")}
            target="_blank"
            rel="noreferrer"
          >
            catalogs
          </a>
        </span>
      </div>
    </div>
  )
}
