import { createContext, ReactNode, useEffect, useState } from "react"
import { authTokens } from "../utils/authTokens"
import { logout, validateUserLoggedIn } from "../services/auth/auth.service"
import * as Sentry from "@sentry/react"

interface AuthContextTypes {
  signIn: () => Promise<void>
  accessTokenUser: string | null
  signOut: () => Promise<void>
  user: UserProps | null
  isAuthenticated: boolean
}

interface AuthProviderTypes {
  children: ReactNode
}

interface UserProps {
  nickname: string | null
  name: string | null
  image: string | null
  email: string | null
}

export const AuthContext = createContext({} as AuthContextTypes)

export default function AuthProvider({ children }: AuthProviderTypes) {
  const [user, setUser] = useState<UserProps | null>(null)
  const accessTokenUser = localStorage.getItem("access-token")
  const RAILS_URL = import.meta.env.VITE_BASEURL_RUBY

  const AFTER_LOGIN_TARGET_URL = window.location.href

  const AUTH_REDIRECT_URL = `${RAILS_URL}/auth/redirect?redirect_url=${AFTER_LOGIN_TARGET_URL}`

  const AUTH_URL = `${RAILS_URL}/auth/azure_activedirectory_v2?auth_origin_url=${AUTH_REDIRECT_URL}`
  const token = localStorage.getItem("access-token")

  async function getUser() {
    try {
      const { data } = await validateUserLoggedIn()
      const response = {
        nickname: data.nickname,
        name: data.name,
        image: data.image,
        email: data.email,
      }
      setUser(response)

      Sentry.setUser({
        username: response.name,
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (token) {
      getUser()
    }
  }, [token])

  async function signIn() {
    window.location.href = AUTH_URL
  }

  async function signOut() {
    try {
      await logout()
      authTokens.cleanTokens()
      setUser(null)
      window.location.href = "/"
    } catch (error) {
      console.error(error)
    }
  }

  const isAuthenticated = !!user

  return (
    <AuthContext.Provider
      value={{ signIn, accessTokenUser, signOut, user, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  )
}
