import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { atomPageBodyKey } from "../../../recoil/atom"

export function CustomLink<As extends React.ElementType = "a">(
  props: CustomLinkProps<As>,
) {
  const {
    href,
    onClick,
    as: Component = "a",
    disabled = false,
    ...otherProps
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [, setPageBodyKey] = useRecoilState(atomPageBodyKey)

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (disabled) {
      event.preventDefault()
      return
    }

    const target = event.currentTarget.target

    if ((!target || target === "_self") && !event.ctrlKey) {
      event.preventDefault()
      href && navigate(href)
      href === location.pathname && setPageBodyKey(Date.now())
    }

    onClick && onClick(event)
  }

  return (
    <Component
      href={disabled ? undefined : href}
      onClick={handleClick}
      aria-disabled={disabled}
      style={disabled ? { pointerEvents: "none", opacity: 0.5 } : {}}
      {...otherProps}
    />
  )
}
