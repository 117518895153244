import { FilterMultiSelect } from "../FilterMultiSelect"
import { useInfiniteQuery } from "@tanstack/react-query"
import { supplierResponsibleList } from "../../../../../../services/customer.service"
import { useDebounceValue } from "usehooks-ts"
import { useCountryConfigs } from "../../../../../../utils/hooks/useCountryConfigs"

function defaultRenderValue(item: baseData) {
  return item.id
}

function defaultRenderName(item: baseData) {
  return item.name
}

function getNextPageParam(lastPage: SupplierResponsibleQuery) {
  return lastPage.page + 1
}

export function FilterSupplierResponsibleSelect(
  props: FilterSupplierResponsibleSelectProps,
) {
  const { removeScroll, removeSearch, label, ...otherConfigs } = props
  const [search, setSearch] = useDebounceValue("", 500)
  const {
    countryCode: [countryCode],
  } = useCountryConfigs()

  async function getSupplierResponsibles({
    pageParam,
  }: {
    pageParam: number
  }): Promise<SupplierResponsibleQuery> {
    const { data } = await supplierResponsibleList({
      search,
      pageNumber: pageParam,
    })
    return {
      supplierResponsibles: data.supplierResponsibles,
      count: data.count,
      page: pageParam,
    }
  }
  const query = useInfiniteQuery({
    queryKey: ["supplierResponsibles", search, countryCode],
    queryFn: getSupplierResponsibles,
    initialPageParam: 1,
    getNextPageParam,
    staleTime: 5 * 60 * 1000,
  })

  function handleSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value)
  }

  async function handleScroll() {
    query.fetchNextPage()
  }

  return (
    <>
      {FilterMultiSelect<baseData>({
        renderName: defaultRenderName,
        renderValue: defaultRenderValue,
        options: query.data?.pages.flatMap((page) => page.supplierResponsibles),
        isLoading: query.isLoading,
        disabled: props.disabled,
        onChange: !removeSearch ? handleSearch : undefined,
        onScroll: !removeScroll ? handleScroll : undefined,
        labelName: label,
        count: query.data?.pages.slice(-1)[0].count,
        ...otherConfigs,
      })}
    </>
  )
}
