 
import { atom } from "recoil"

export const atomRemoverItem = atom({
  key: "atomRemoverItem",
  default: false,
})

export const atomSuccess = atom<boolean | undefined>({
  key: "atomSuccess",
  default: undefined,
})

export const atomFailure = atom({
  key: "atomFailure",
  default: false,
})

export const atomFailureMessage = atom({
  key: "atomFailureMessage",
  default: "",
})
export const atomSuccessMessage = atom({
  key: "atomSuccessMessage",
  default: "",
})

export const atomStatus = atom({
  key: "atomStatus",
  default: "",
})

export const atomPageBodyKey = atom<number>({
  key: "atomReloadPageBody",
  default: Date.now(),
})

export const atomShow404Page = atom<boolean>({
  key: "atomShow404Page",
  default: false,
})
