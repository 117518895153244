import React, { useEffect, useRef, useState } from "react"
import { Dropdown, Form } from "react-bootstrap"
import { BsSearch } from "react-icons/bs"
import { LoadingComponent } from "../Loading"
import InfiniteScroll from "react-infinite-scroll-component"
import { useTranslation } from "react-i18next"
import { useCountryConfigs } from "../../utils/hooks/useCountryConfigs"

interface DropdownInputMultiSelectProps<T> {
  array?: T[]
  description?: string
  placeholder?: string
  value: T[]
  handleValue?: (value: T[]) => void
  onClick: (item: T) => void
  renderName: (item: T) => string
  renderValue: (item: T) => string | number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onScroll?(): void
  count?: number
  isLoading?: boolean
  id?: string
  selecteds: Array<string | number>
  emptyState?: React.ReactNode
  onEnterPress?: () => void
  disabled?: boolean
  selectAll?: boolean
}

export default function DropdownInputMultiSelect<T>({
  array,
  description = "",
  placeholder,
  value,
  onClick,
  renderName,
  renderValue,
  onChange,
  onScroll,
  isLoading,
  id,
  selecteds,
  count,
  emptyState,
  disabled,
  onEnterPress,
  selectAll = false,
}: DropdownInputMultiSelectProps<T>) {
  const { t } = useTranslation()
  const { findPluralRule } = useCountryConfigs()
  const [toggleText, setToggleText] = useState<string>(description)
  const [searchText, setSearchText] = useState<string>("")

  const inputRef = useRef<HTMLInputElement | null>(null)
  const toggleRef = useRef<HTMLButtonElement | null>(null)

  const handleSearchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchText(event.target.value)
    onChange && onChange(event)
  }

  const handleScroll = async () => {
    onScroll && onScroll()
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && onEnterPress) onEnterPress()
  }

  const isItemSelected = (item: T) =>
    selecteds.some((selected) => String(selected) === String(renderValue(item)))

  const handleSelectAll = () => {
    if (array) {
      array.forEach((item) => {
        if (!isItemSelected(item)) {
          onClick(item)
        }
      })
    }
  }

  const handleDeselectAll = () => {
    if (array) {
      array.forEach((item) => {
        if (isItemSelected(item)) {
          onClick(item)
        }
      })
    }
  }

  useEffect(() => {
    if (inputRef.current?.id) {
      const label = document.querySelector(
        `label[for="${inputRef.current.id}"]`,
      )
      const handleClick = () => toggleRef.current?.click()
      label?.addEventListener("click", handleClick)
      return () => label?.removeEventListener("click", handleClick)
    }
  }, [inputRef.current?.id])

  useEffect(() => {
    if (selecteds.length === 0) {
      setToggleText(description)
    } else if (
      selecteds.length === 1 &&
      value.find((value) => renderValue(value) === String(selecteds[0]))
    ) {
      setToggleText(renderName(value[0]))
    } else {
      setToggleText(
        `${selecteds.length} ${t("Global.attributes.itemsSelected", {
          items: t(
            `Global.attributes.item.${findPluralRule(selecteds.length)}`,
          ),
        }).toLowerCase()}`,
      )
    }
  }, [value])

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-secondary"
        className="dropdown-input-button form-select bg-white text-dark w-100 d-flex justify-content-between border"
        data-testid={`${id}FilterToggle`}
        ref={toggleRef}
        disabled={disabled}
      >
        <span className="text-truncate">{toggleText}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="w-100"
        renderOnMount
        data-testid={`${id}FilterContent`}
      >
        <Dropdown.Header hidden={!onChange}>
          <div className="w-100 position-relative d-flex">
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder={placeholder}
              value={searchText}
              onChange={handleSearchChange}
              className="dropdown-input-control"
              onKeyDown={handleKeyPress}
              data-testid={`${id}FilterSearch`}
              ref={inputRef}
            />
            <BsSearch
              size="1.1rem"
              className="text-secondary position-absolute end-0 h-100 me-2 pe-1"
            />
          </div>
        </Dropdown.Header>

        {selectAll && (
          <div className="px-3 pb-2 d-flex justify-content-between">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                handleSelectAll()
              }}
            >
              {t("Global.actions.checkAll")}
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                handleDeselectAll()
              }}
            >
              {t("Global.actions.uncheckAll")}
            </a>
          </div>
        )}

        <div
          style={{ maxHeight: "192px", overflowY: "auto" }}
          data-testid={`${id}FilterItensMenu`}
          id={`${id}scrolableMenuDiv`}
        >
          {isLoading ? (
            <Dropdown.Item
              className="d-flex justify-content-center align-items-center"
              data-testid={`${id}FilterLoading`}
            >
              <LoadingComponent />
            </Dropdown.Item>
          ) : emptyState && (!array || array?.length === 0) ? (
            <>{emptyState}</>
          ) : (
            <InfiniteScroll
              dataLength={array?.length || 0}
              next={handleScroll}
              hasMore={!!onScroll && !!array && !!count && array.length < count}
              loader={
                <Dropdown.Item className="d-flex justify-content-center align-items-center">
                  <LoadingComponent />
                </Dropdown.Item>
              }
              scrollableTarget={`${id}scrolableMenuDiv`}
            >
              {array?.map((item, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClick(item)
                  }}
                  className={`${
                    isItemSelected(item) ? "bg-success bg-opacity-25" : ""
                  } dropdown-input-item`}
                  data-testid={`${id}FilterItem${renderValue(item)}`}
                >
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      checked={isItemSelected(item)}
                      readOnly
                      className="me-2"
                    />
                    <span title={renderName(item)}>{renderName(item)}</span>
                  </div>
                </Dropdown.Item>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}
