import { Suspense, useEffect } from "react"
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom"
import { DefaultLayout } from "../layout/Default"
import * as Page from "@pages"
import ReactGA from "react-ga4"
import { NotFound } from "@pages"
import * as Lazy from "./Lazy"
import { Spinner } from "react-bootstrap"
import { authTokens } from "../utils/authTokens"
import { Forbidden } from "../pages/Errors/Forbidden"
import { InternalServerError } from "../pages/Errors/InternalServerError"
import { PrivateIsBuyerRepurposal } from "../components/PrivateIsBuyerRepurposal"

export function Router() {
  const production = import.meta.env.VITE_AMBIENTE === "production"
  const pathname = window.location.pathname
  const [params] = useSearchParams()

  useEffect(() => {
    if (params.has("auth_token")) authTokens.setTokensByParams()
  }, [])

  useEffect(() => {
    if (production) {
      ReactGA.send({ hitType: "pageview", page: pathname })
    }
  }, [pathname])

  const isEnabledNewExternalRepurposal =
    import.meta.env.VITE_NEW_EXTERNAL_REPURPOSAL_ENABLED === "true"

  if (params.has("auth_token")) authTokens.setTokensByParams()
  if (!authTokens.tokensExist()) {
    return (
      <Suspense
        fallback={
          <div className="min-vh-100 d-flex justify-content-center align-items-center bg-light">
            <Spinner animation="border" role="status" variant="success" />
          </div>
        }
      >
        <Routes>
          <Route path="*" element={<Page.Login />} />
          <Route
            path="recolocacao/:country?/:id"
            element={
              <PrivateIsBuyerRepurposal>
                {(isEnabledNewExternalRepurposal && (
                  <Lazy.ExternalRepurposal />
                )) || <Lazy.NewRepurposal isBuyerFormalization />}
              </PrivateIsBuyerRepurposal>
            }
          />
          <Route path="checkin" element={<Lazy.StockCheckinExternal />} />
          <Route path="payment/:id" element={<Lazy.ExtenalBill />} />
        </Routes>
      </Suspense>
    )
  }

  return (
    <Suspense
      fallback={
        <div className="min-vh-100 d-flex justify-content-center align-items-center bg-light">
          <Spinner animation="border" role="status" variant="success" />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Lazy.Home />} />
          <Route path="catalogs" element={<Lazy.CatalogList />} />
          <Route path="catalogs/cadastrar" element={<Lazy.CatalogItem />} />
          <Route path="catalogs/item/:id" element={<Lazy.CatalogItem />} />
          <Route
            path="catalogs/item/:id/:edicao"
            element={<Lazy.CatalogItem />}
          />
          <Route
            path="catalogs/manufacturers"
            element={<Lazy.CatalogManufacturers />}
          />
          <Route path="estoque" element={<Lazy.Stock />} />
          <Route path="estoque/checkin" element={<Lazy.StockCheckins />} />
          <Route path="stocks/new" element={<Lazy.StockNew />} />
          <Route path="suppliers/:id/stocks/availables">
            <Route index element={<Lazy.StockView />} />
            <Route
              path="sales_channel/:salesChannel"
              element={<Lazy.StockView />}
            />
          </Route>
          <Route
            path="stock/view/:id"
            element={<Lazy.StockView viewStockOnly={true} />}
          />
          <Route
            path="estoque/relatorio/gestao-de-estoque/*"
            element={<Lazy.StockHistory />}
          >
            <Route path="page/:pg" element={<Lazy.StockHistory />} />
          </Route>
          <Route path="customers/" element={<Lazy.Customers />} />
          <Route path="customer/view/:id" element={<Lazy.CustomersView />} />
          <Route path="customers/groups" element={<Lazy.CustomersGroups />} />
          <Route path="financial/*" element={<Lazy.Financial />}>
            <Route path=":page" element={<Lazy.Financial />} />
          </Route>
          <Route path="logistics/:page?" element={<Lazy.Logistics />} />
          <Route path="payments/billets/new" element={<Lazy.NewBillets />} />
          <Route
            path="payments/billets/new/FeedBack"
            element={<Lazy.FeedBackNewBillets />}
          />
          <Route path="stocks/:id/recolocations">
            <Route index element={<Lazy.NewRepurposal />} />
            <Route
              path="negotiation_kind/:negotiationKind"
              element={<Lazy.NewRepurposal />}
            />
          </Route>
          <Route
            path="stocks/:id/recolocations/:code/edit/:version"
            element={<Lazy.NewRepurposal />}
          />
          <Route
            path="stocks/:id/recolocations/:code/edit/:version/negotiation_kind/:negotiationKind"
            element={<Lazy.NewRepurposal />}
          />
          <Route
            path="stocks/:id/recolocations/:code/newVersion"
            element={<Lazy.NewRepurposal />}
          />
          <Route
            path="stocks/:id/recolocations/:code/newVersion/negotiation_kind/:negotiationKind"
            element={<Lazy.NewRepurposal />}
          />
          <Route
            path="recolocacoes/visualizar/:id"
            element={<Lazy.ViewRepurposal />}
          />

          <Route path="recolocacoes" element={<Lazy.Repurposal />} />
          <Route
            path="config/external-portal"
            element={<Lazy.ExternalPortalConfigs />}
          />
          <Route
            path="external-portal/:id/reports"
            element={<Lazy.ExternalPortalReports />}
          />
          <Route
            path="config/external-users"
            element={<Lazy.ExternalUsers />}
          />
          <Route path="config" element={<Navigate to="external-portal" />} />
          <Route
            path="reports/kpis/billing-results"
            element={<Lazy.KpisReport />}
          />
          <Route
            path="reports/recolocations-flow/maps"
            element={<Lazy.DestinyRecolocations />}
          />
          <Route
            path="reports/recolocations-flow/performance"
            element={<Lazy.RepurposalsPerformance />}
          />
          <Route
            path="reports/recolocations-flow/checkins-tracking"
            element={<Lazy.CheckinsTracking />}
          />
          <Route
            path="reports/kpis/commercial/performance"
            element={<Lazy.CommercialPerformance />}
          />
          <Route
            path="smart-rec/stocks/:stockId/recolocations/optimizer"
            element={<Lazy.SmartRecRecolocationsOptimizer />}
          />
          <Route
            path="smart-rec/stocks/:stockId/driver"
            element={<Lazy.SmartRecStockDriver />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="estoque/visualizar-pdf/*" element={<Lazy.StockPdf />} />
        <Route path="checkin" element={<Lazy.StockCheckinExternal />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="forbidden" element={<Forbidden />} />
        <Route path="internal-server-error" element={<InternalServerError />} />
        <Route
          path="recolocacoes/visualizar-pdf/:id"
          element={<Lazy.ViewRepurposalPDF />}
        />
        <Route
          path="recolocacao/:country?/:id"
          element={
            <PrivateIsBuyerRepurposal>
              {(isEnabledNewExternalRepurposal && (
                <Lazy.ExternalRepurposal />
              )) || <Lazy.NewRepurposal isBuyerFormalization />}
            </PrivateIsBuyerRepurposal>
          }
        />
        <Route path="payment/:id" element={<Lazy.ExtenalBill />} />
      </Routes>
    </Suspense>
  )
}
